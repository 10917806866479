import '@cyntler/react-doc-viewer/dist/index.css';
import * as Sentry from '@sentry/react';
import React from 'react';
import { Helmet } from 'react-helmet-async';

import { getEnvVar, isProduction } from '#shared/envs';
import { injectLink } from '#shared/utils/head';

import Router from '#extranet/Router';
import { injectGTM, injectGTMNoScript } from '#extranet/utils/tagmanager';

const App: React.FC = () => (
  <>
    <Helmet>
      {injectLink()}
      <title>{getEnvVar('VITE_TITLE_EXTRANET')}</title>
      <meta itemProp="description" content={getEnvVar('VITE_DESCRIPTION')} />
      {injectGTM()}
    </Helmet>
    {injectGTMNoScript()}
    <Router />
  </>
);

const app = isProduction() ? Sentry.withErrorBoundary(App, { showDialog: true }) : App;

export default app;
