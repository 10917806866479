import i18n, { t } from 'i18next';
import { initReactI18next } from 'react-i18next';

import * as accordion from '#shared/UI/Accordion/translations';
import * as uploadInput from '#shared/UI/Input/UploadInput/translations';
import * as pageHeader from '#shared/UI/Navigation/PageHeader/translations';
import * as tablePlaceholder from '#shared/UI/TablePlaceholder/translations';
import * as errors from '#shared/components/Error/translations';
import * as sharedTranslationsFr from '#shared/translations';

import * as navigation from '#extranet/components/Navigation/translations';
import * as claims from '#extranet/features/Claim/translations';
import * as company from '#extranet/features/Company/translations';
import * as contracts from '#extranet/features/Contract/translations';
import * as dashboard from '#extranet/features/Dashboard/translations';
import * as events from '#extranet/features/Event/translations';
import * as invoices from '#extranet/features/Invoice/translations';
import * as manager from '#extranet/features/Manager/translations';
import * as parameter from '#extranet/features/Parameter/translations';
import * as quote from '#extranet/features/Quote/translations';
import * as task from '#extranet/features/Task/translations';
import * as uploadedFile from '#extranet/features/UploadedFile/translations';
import sharedFr from '#extranet/i18n/fr.json';

const resources = {
  fr: {
    shared: { ...sharedFr, ...sharedTranslationsFr.fr.shared, ...sharedTranslationsFr.fr.productTypes },
    errors: errors.fr,
    events: events.fr,
    claims: claims.fr,
    contracts: contracts.fr,
    dashboard: dashboard.fr,
    invoices: invoices.fr,
    company: company.fr,
    quote: quote.fr,
    task: task.fr,
    manager: manager.fr,
    uploadedFile: uploadedFile.fr,
    accordion: accordion.fr,
    pageHeader: pageHeader.fr,
    uploadInput: uploadInput.fr,
    parameter: parameter.fr,
    navigation: navigation.fr,
    violations: sharedTranslationsFr.fr.violations,
    companyProperties: sharedTranslationsFr.fr.companyProperties,
    stripe: sharedTranslationsFr.fr.stripe,
    ui: {
      tablePlaceholder: tablePlaceholder.fr,
    },
  },
};

i18n.use(initReactI18next).init({
  resources,
  debug: false,
  lng: 'fr',
  interpolation: {
    escapeValue: false,
    skipOnVariables: false,
  },
  react: {
    transSupportBasicHtmlNodes: true,
    transKeepBasicHtmlNodesFor: ['strong', 'b', 'br', 'i'],
  },
  saveMissing: true,
  missingKeyHandler: (_lng, ns, key) => {
    console.error(`missing translation in namespace: '${ns}' with key '${key}'`);
  },
});

i18n.services.formatter?.add(
  'translatedList',
  (
    value: string[],
    _lng: any,
    options: {
      baseKey: string;
      spacer?: string;
    },
  ) => {
    return value.map((value) => t(`${options.baseKey}.${value}`)).join(options.spacer || ', ');
  },
);

export default i18n;
