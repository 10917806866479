import React, { lazy, Suspense } from 'react';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';

import Loader from '#shared/UI/Loader';
import Notification from '#shared/UI/Notification';
import DebugToolbar from '#shared/components/DebugToolbar';
import Error404 from '#shared/components/Error/404';
import SharedPath from '#shared/constants/paths';
import { getEnvVar } from '#shared/envs';

import { SECURE_BASE_PATH } from '#extranet/features/path';

const SecureIndex = lazy(() => import('#extranet/pages'));

const Router: React.FC = () => (
  <BrowserRouter basename={getEnvVar('VITE_BASE_NAME')}>
    <Notification />
    <Suspense fallback={<Loader />}>
      <DebugToolbar />
      <Routes>
        <Route path={SharedPath.NOT_FOUND} element={<Error404 />} />
        <Route path={`${SECURE_BASE_PATH}*`} element={<SecureIndex />} />
        <Route path="*" element={<Navigate to={SharedPath.NOT_FOUND} replace />} />
      </Routes>
    </Suspense>
  </BrowserRouter>
);

export default Router;
